import { add, format, isAfter, isBefore, isWithinInterval } from 'date-fns'
import { useMemo } from 'react'

import { useUser } from '../../contextManagers/user.context'
import { styleForExercise } from '../../styles/exerciseItem.style'
import {
  ExercisesDiv,
  MobileTimelineContainer,
  MobileTimelineFooter,
} from '../../styles/timeline/mobileTimeline.style'
import { Exercise, ExerciseStatus } from '../../types/schemas/emarking'
import { TrackMap } from '../../types/timeline'
import { groupByProperty, now } from '../../utils'
import ExerciseButton from '../exercise/ExerciseButtons'
import NoExercisesBanner from './NoExercisesBanner'

const MobileTimeline = ({ trackMap }: { trackMap: TrackMap }) => {
  const aWeekFromNow = add(now(), { days: 7 })
  const { userDetails } = useUser()

  function toSubtext(moduleCode: string, dueDate: Date) {
    return `${moduleCode} | ${format(dueDate, 'EEE, h:mm a')}`
  }

  const allExercises: Exercise[] = useMemo(
    () =>
      Object.values(trackMap)
        .reduce((acc, sublist) => acc.concat(...sublist), [])
        .reduce((acc, sublist) => acc.concat(sublist), []),
    [trackMap]
  )

  const exercisesNext7Days = useMemo(
    () =>
      allExercises
        .filter((exercise: Exercise) =>
          isWithinInterval(exercise.deadline, {
            start: now(),
            end: aWeekFromNow,
          })
        )
        .sort((exA, exB) => {
          if (isBefore(exA.deadline, exB.deadline)) return -1
          if (isAfter(exA.deadline, exB.deadline)) return 1
          return 0
        }),
    [allExercises]
  )

  const exercisesOnGoing = useMemo(
    () =>
      allExercises
        .filter((exercise: Exercise) => isAfter(exercise.deadline, aWeekFromNow) && exercise.isOpen)
        .sort((exA, exB) => {
          if (isBefore(exA.deadline, exB.deadline)) return -1
          if (isAfter(exA.deadline, exB.deadline)) return 1
          return 0
        }),
    [allExercises]
  )

  const generateExercisesOutput = (exercises: Exercise[]) => {
    const exercisesByModule: { [code: string]: Exercise[] } = groupByProperty(
      exercises,
      'moduleCode',
      'deadline'
    )

    return Object.values(exercisesByModule)
      .flat()
      .map((exercise: Exercise, index) => (
        <ExerciseButton
          key={index}
          exercise={exercise}
          subtextChild={toSubtext(exercise.moduleCode, exercise.deadline)}
          css={{
            ...styleForExercise(exercise, {
              allowRedBorder: !userDetails?.isStaffOrTAForModule(exercise.moduleCode),
            }),
            width: '90%',
          }}
        />
      ))
  }

  const exercisesOutputNextWeek = useMemo(() => {
    if (exercisesNext7Days && exercisesNext7Days.length > 0) {
      return generateExercisesOutput(exercisesNext7Days)
    }
  }, [trackMap])

  const exercisesOutputOngoing = useMemo(() => {
    if (exercisesOnGoing && exercisesOnGoing.length > 0) {
      return generateExercisesOutput(exercisesOnGoing)
    }
  }, [trackMap])

  return (
    <MobileTimelineContainer>
      <h2>Due this week</h2>
      <ExercisesDiv>
        {exercisesNext7Days && exercisesNext7Days.length > 0 ? (
          exercisesOutputNextWeek
        ) : (
          <NoExercisesBanner text="You have no exercises due this week!" />
        )}
      </ExercisesDiv>
      {exercisesOnGoing && exercisesOnGoing.length > 0 && (
        <>
          <h2 style={{ marginTop: '2rem' }}>Ongoing exercises</h2>
          <ExercisesDiv>{exercisesOutputOngoing}</ExercisesDiv>
        </>
      )}

      <MobileTimelineFooter>To see the full timeline, rotate your phone</MobileTimelineFooter>
    </MobileTimelineContainer>
  )
}

export default MobileTimeline
