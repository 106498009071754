import { styled } from './stitches.config'

const LIST_ITEM_LARGE_HEIGHT = '2.5rem'
const LIST_ITEM_STANDARD_DIMENSION = '2.75rem'

export const ActionableListContainer = styled('div', {
  display: 'flex',
  flex: 1,
  minWidth: 0,
  gap: '0.75rem',
})

export const ListBlock = styled('div', {
  flex: 1,
  minWidth: 0,
})

export const ActionBlock = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  minWidth: '1.75rem',
  cursor: 'pointer',
})

export const ListItemContainer = styled('div', {
  display: 'flex',
  flex: 1,
  minWidth: 0,
  alignItems: 'center',
  gap: '0.5rem',
  width: '100%',

  variants: {
    size: {
      lg: {
        height: LIST_ITEM_LARGE_HEIGHT,
      },
    },
  },
})

export const ListItem = styled('div', {
  display: 'flex',
  flex: 1,
  minWidth: 0,
  gap: '0.5rem',
  flexDirection: 'column',
  justifyContent: 'center',
  variants: {
    inline: {
      true: {
        flexDirection: 'row',
      },
    },
    fillSpace: {
      true: {
        flex: '1 0 0',
        width: '100%',
      },
    },
  },
})

export const ListItemMainText = styled('span', {
  whiteSpace: 'nowrap',
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  minWidth: 0,
  alignItems: 'center',
  width: '100%',
  gap: '0.5rem',
  display: 'flex',
})

export const ListItemSubText = styled('span', {
  fontSize: '0.8rem',
  color: '$lowContrast',
})

export const ActionItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: LIST_ITEM_STANDARD_DIMENSION,
  width: LIST_ITEM_STANDARD_DIMENSION,
  variants: {
    size: {
      doubleWidth: {
        justifyContent: 'end',
        width: `calc(${LIST_ITEM_STANDARD_DIMENSION} * 2 + 0.5rem)`,
      },
      lg: {
        // 0.75rem being the padding of a Tab used as list
        // This needs refactoring of Tabs
        margin: '0.75rem',
        height: LIST_ITEM_LARGE_HEIGHT,
      },
    },
  },
})
